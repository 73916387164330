<template>
  <section class="vSectionQr section-profile">
    <div v-if="!detail">
      <h2 class="section-profile__title">
        Список столиков
      </h2>
      <div class="vSectionQr__header">
        Поиск столов
        <div class="vSectionQr__search">
          <div class="vSectionQr__search-wrap">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_7064_211293)">
                <path
                  d="M19 19L15.71 15.71M15.7138 6.8382C18.1647 9.28913 18.1647 13.2629 15.7138 15.7138C13.2629 18.1647 9.28913 18.1647 6.8382 15.7138C4.38727 13.2629 4.38727 9.28913 6.8382 6.8382C9.28913 4.38727 13.2629 4.38727 15.7138 6.8382V6.8382Z"
                  stroke="#80869A"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_7064_211293">
                  <rect
                    width="24"
                    height="24"
                    fill="white"
                  />
                </clipPath>
              </defs>
            </svg>

            <input
              v-model="range_from"
              v-maska="'######'"
              type="text"
              class="vSectionQr__input"
              placeholder="С:"
            >
          </div>

          <div class="vSectionQr__search-wrap">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_7064_211293)">
                <path
                  d="M19 19L15.71 15.71M15.7138 6.8382C18.1647 9.28913 18.1647 13.2629 15.7138 15.7138C13.2629 18.1647 9.28913 18.1647 6.8382 15.7138C4.38727 13.2629 4.38727 9.28913 6.8382 6.8382C9.28913 4.38727 13.2629 4.38727 15.7138 6.8382V6.8382Z"
                  stroke="#80869A"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_7064_211293">
                  <rect
                    width="24"
                    height="24"
                    fill="white"
                  />
                </clipPath>
              </defs>
            </svg>
            <input
              v-model="range_to"
              v-maska="'######'"
              type="text"
              class="vSectionQr__input"
              placeholder="По:"
            >
          </div>
          <button
            type="button"
            class="vSectionQr__btn"
            @click="search"
          >
            Найти
          </button>
          <button
            v-show="reset"
            type="button"
            class="vSectionQr__btn vSectionQr__btn--red"
            @click="resetSerch"
          >
            Сбросить
          </button>
        </div>
      </div>
      <div
        v-if="GET_TABLES.tables && !GET_TABLES.tables.length"
        class="vSectionQr__empty"
      >
        Ничего не найдено, попробуйте изменить параметры
      </div>
      <div
        v-else
        class="vSectionQr__list-wrap"
      >
        <ul class="vSectionQr__list">
          <li
            v-for="table in firstRowTables"
            :key="table.id"
            class="vSectionQr__item"
            @click="onDetailTable(table)"
          >
            Стол {{ table.code }}
            <span
              v-if="table.temporary_code"
              class="vSectionQr__code"
            >Код: {{ table.temporary_code }}</span>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10 16L14 12L10 8"
                stroke="#80869A"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </li>
        </ul>

        <ul class="vSectionQr__list">
          <li
            v-for="table in secondRowTables"
            :key="table.id"
            class="vSectionQr__item"
            @click="onDetailTable(table)"
          >
            Стол {{ table.code }}
            <span
              v-if="table.temporary_code"
              class="vSectionQr__code"
            >Код: {{ table.temporary_code }}</span>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10 16L14 12L10 8"
                stroke="#80869A"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </li>
        </ul>
      </div>
      <vPargnation
        v-if="GET_TABLES.tables && GET_TABLES.tables.length"
        :pages="GET_TABLES.countPage"
        :page="GET_TABLES.pageCurrent"
        @onHandler="onClickPagination"
      />
    </div>

    <div v-if="detail">
      <vSectionQrDetail
        :table-id="tableId"
        @onDetail="onDetail"
        @togglePreloader="togglePreloader"
      />
    </div>

    <div
      v-show="isLoading"
      class="vSectionQr__preloader"
    >
      <img
        src="~@/assets/images/preloader.gif"
        alt="Загрузка..."
      >
      <div>Ищем столик</div>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import axios from 'axios'
import vPargnation from '@/components/v-pagination.vue'
import vSectionQrDetail from '@/components/profile/v-section-qr-detail'
export default {
  name: 'VSectionRr',
  components: {
    vPargnation,
    vSectionQrDetail,
  },
  data() {
    return {
      detail: false,
      tableData: {},
      tableId: {},
      range_from: '',
      range_to: '',
      isLoading: false,
      reset: false,
    }
  },
  computed: {
    ...mapGetters('restaurant', ['GET_TABLES', 'GET_QR']),
    ...mapGetters('user', ['GET_AUTHENTIFICATED']),
    firstRowTables() {
      return this.GET_TABLES && this.GET_TABLES.tables
        ? this.GET_TABLES.tables.slice(0, 5)
        : []
    },
    secondRowTables() {
      return this.GET_TABLES && this.GET_TABLES.tables
        ? this.GET_TABLES.tables.slice(5)
        : []
    },
  },
  async mounted() {
    if (this.range_from || this.range_to) this.reset = true

    await this.FETCH_TABLES({
      token: this.GET_AUTHENTIFICATED,
      page: 1,
    })
    this.TOGGLE_PRELOADER(false)
  },
  methods: {
    ...mapActions('preloader', ['TOGGLE_PRELOADER']),
    ...mapActions('restaurant', [
      'FETCH_TABLES',
      'DOWNLOAD_QR_CODE',
      'FETCH_SELECTED_TABLE',
    ]),
    togglePreloader(status) {
      this.TOGGLE_PRELOADER(status)
    },
    async resetSerch() {
      this.isLoading = true
      await this.FETCH_TABLES({
        token: this.GET_AUTHENTIFICATED,
        page: 1,
      })
      this.range_from = ''
      this.range_to = ''
      this.reset = false
      this.isLoading = false
    },
    async search() {
      if (this.range_from.length || this.range_to.length) {
        this.isLoading = true
        await this.FETCH_TABLES({
          token: this.GET_AUTHENTIFICATED,
          page: 1,
          range_from: this.range_from.length ? parseInt(this.range_from) : null,
          range_to: this.range_to.length ? parseInt(this.range_to) : null,
        })
        this.reset = true
        this.isLoading = false
      }
    },
    async onClickPagination(num) {
      this.TOGGLE_PRELOADER(true)
      await this.FETCH_TABLES({
        page: num,
        range_from: this.range_from.length ? parseInt(this.range_from) : null,
        range_to: this.range_to.length ? parseInt(this.range_to) : null,
        token: this.GET_AUTHENTIFICATED,
      })
      this.TOGGLE_PRELOADER(false)
    },
    // async selectTable(table) {
    //   await this.$router.push({
    //     name: 'tableDetail',
    //     params: {
    //       id: table.id,
    //       range_from: this.range_from,
    //       range_to: this.range_to,
    //     },
    //   })
    // },

    onDetail() {
      this.detail = !this.detail
    },

    toggleId(table) {
      this.tableId = table.id
    },

    onDetailTable(table) {
      this.onDetail()
      this.toggleId(table)
    },
  },
}
</script>
<style lang="scss">
.vSectionQr {
  @media (max-width: 880px) {
    &::after {
      content: '';
      position: fixed;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      background: #f8f9fd;
      z-index: -1;
    }
  }
  &__header {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 32px;
    max-width: 596px;
    width: 100%;
    @media (max-width: 880px) {
      margin-bottom: 4px;
    }
  }
  &__search {
    padding-top: 12px;
    width: 100%;
    display: flex;
    align-items: center;
    @media (max-width: 880px) {
      flex-wrap: wrap;
    }
    &-wrap {
      width: 100%;
      max-width: 150px;
      margin-right: 12px;
      position: relative;
      @media (max-width: 880px) {
        margin-right: 0;
        width: calc(50% - 8px);
        max-width: none;
        margin-bottom: 12px;
        &:first-child {
          margin-right: 8px;
        }
        &:nth-child(2) {
          margin-left: 8px;
        }
      }
      svg {
        position: absolute;
        left: 8px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
  &__btn {
    width: 86px;
    height: 30px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(223.61deg, #547aff 0%, #413dff 100%);
    border-radius: 6px;
    margin-right: 12px;
    border: none;
    color: #fff;
    cursor: pointer;
    @media (max-width: 880px) {
      margin-right: 0;
      width: 100%;
      margin-bottom: 12px;
    }
    &:disabled {
      background: #d5dbf1;
      cursor: no-drop;
    }
    &--red {
      background: rgba(254, 195, 195, 0.15);
      border: 1px solid #ec4e4e;
      color: #ec4e4e;
      margin-right: 0;
      width: auto;
      flex: auto;
    }
  }
  &__empty {
    color: #80869a;
    font-size: 16px;
  }
  &__input {
    width: 100%;
    border: 1px solid #e6e7eb;
    border-radius: 6px;
    height: 30px;
    padding: 0 8px 0 36px;
    &:focus {
      border: 1px solid #6764ff;
    }
    &::placeholder {
      font-weight: 400;
      font-size: 13px;
      color: #80869a;
    }
  }
  &__list {
    margin-bottom: 24px;
    max-width: 596px;
    width: 100%;
    //min-height: 446px;
    @media (max-width: 880px) {
      min-height: auto;
      margin-bottom: 0;
      &:not(:first-child) {
        margin-top: 0;
      }
    }
    &-wrap {
      display: grid;
      max-width: 1224px;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr;
      gap: 0px 32px;
      grid-template-areas: '. .';
      @media (max-width: 880px) {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr;
        grid-template-areas:
          '.'
          '.';
        gap: 0;
        padding-bottom: 20px;
      }
    }
  }
  &__item {
    margin-bottom: 24px;
    background: #fff;
    border: 1px solid #e6e7eb;
    box-sizing: border-box;
    border-radius: 11px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 22px 24px;
    cursor: pointer;
  }
  &__back {
    display: flex;
    align-items: center;
    cursor: pointer;
    @media (max-width: 880px) {
      margin-bottom: 15px;
    }
  }
  &__code {
    margin-right: auto;
    margin-left: 30px;
    color: #c2c5ce;
    font-size: 12px;
  }
  &__qr {
    border: 1px solid #e6e7eb;
    border-radius: 11px;
    max-width: 596px;
    width: 100%;
    background: #fff;
    padding: 58px 24px 26px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    &--black {
      background: #000;
      color: #fff;
    }
    img {
      width: 292px;
      height: 292px;
      margin-bottom: 50px;
    }
  }
  &__download {
    margin-left: auto;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    svg {
      margin-right: 7px;
    }
  }
  &__link {
    color: #010d35;
    display: inline-flex;
    align-items: center;
    &:hover {
      span {
        text-decoration: none;
      }
    }
    span {
      text-decoration: underline;
      margin-left: 5px;
    }
  }
  &__preloader {
    background: rgba(220, 222, 227, 0.9);
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 999999;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #010d35;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    img {
      width: 90px;
      height: 90px;
    }
  }
  .vPagination {
    @media (max-width: 880px) {
      position: fixed;
      bottom: 0;
      left: 95px;
      right: 0;
      padding-bottom: 90px;
      padding-top: 10px;
      background: #f8f9fd;
      z-index: 2;
    }
    @media (max-width: 770px) {
      left: 16px;
    }
  }
}
</style>
